import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FormattedMessage, injectIntl } from "react-intl";
import Pagination from "react-js-pagination";

import FormatDate from "../common/FormatDate";
import { getSmiles } from "../../actions/smiles";
import { getToken } from "../../actions/get_token";
import remoteLog from "../../common/logging";
import PersonName from "../common/PersonName";
import FormatNumber from "../common/FormatNumber";
import { statuses_predict } from "../../common/constants";
import { deployedRussia, deployedUSA } from "../../common/utils";
import Loader from "../common/loadingInProgress";
import { getAllShipments } from "../../actions/deliveries";
import { getDoctorsPays, getDoctorsTasks, getPatientsData } from "../../actions/dashboard";
import {
  S_3D_PLAN_APPROVAL,
  S_RETAKE_IMPRESSIONS,
  S_UNFILLED_CASE,
  S_WAITING_FOR_A_PAYMENT,
} from "../../common/courses";
import { PLAN_LANG_OPTION_MAP, DN_MAP_PALMER } from "../../common/constants";
import precise from "../../assets/img/precise.svg";
import PatientBonuses from "./patient_bonuses";

const predict_courses = {
  "pat_table.full": 1,
  "pat_table.short": 2,
  "pat_table.one.jaw": 3,
  "pat_table.super.short": 4,
  "pat_table.unlimited": 5,
  C_3D_PLAN_V1: 6,
  C_RETAINERS_USA: 8,
};
const renderCourse = (id) => {
  if (id !== null) {
    const course = Object.keys(predict_courses).find((key) => predict_courses[key] === id);
    return (
      <p className="font-sm" style={{ width: "100px" }}>
        <FormattedMessage id={`${course}`} />
      </p>
    );
  } else {
    return null;
  }
};

const PLAN_LINK_V2 = (patient_id, token, lang) =>
  `//${process.env.PLAN3D_API}/show-${patient_id}-secure-${token}-lang-${lang}-version=2`;
const PLAN_LINK_V2_RU = (patient_id, token, lang) =>
  `//${process.env.PLAN3D_API_RU}/show-${patient_id}-secure-${token}-lang-${lang}-version=2`;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patients: state.patients,
    tasks: state.doctorTasks,
    spinner: state.spinner,
    pays: state.doctorPays,
    lang: state.intl,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (status, ordering, search, page, perPage) =>
      dispatch(getPatientsData(status, ordering, search, page, perPage)),
    getTasks: () => dispatch(getDoctorsTasks()),
    getPays: () => dispatch(getDoctorsPays()),
    getSmiles: (doctor_id) => dispatch(getSmiles(doctor_id)),
    getShipments: () => dispatch(getAllShipments()),
    getToken: () => dispatch(getToken()),
  };
};

class PatientsListNew extends Component {
  static initialState = {
    perPage: 100,
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredPatients: props.patients.patients,
      paymentState: false,
      payment_alert: false,
      sort: {
        order: "desc",
        name: "patient_id",
      },
      status: false,
      ordering: "correction.timestamp.desc", //"-patient_id"
      search: false,
      page: this.getPageFromURL() ?? 1,
      perPage: PatientsListNew.initialState.perPage,
      selectedOptions: [],
      showProgress: false,
      actionRequired: false,
    };
    this.togglePayment = this.togglePayment.bind(this);
    this.statusColor = this.statusColor.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
    this.fm = this.fm.bind(this);
    this.submit = this.submit.bind(this);
    this.clear = this.clear.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.open3dPlan = this.open3dPlan.bind(this);
    this.onSelectPerPage = this.onSelectPerPage.bind(this);
    this.renderTableBody = this.renderTableBody.bind(this);
  }

  getPageFromURL() {
    let searchParams = new URLSearchParams(this.props.location.search);
    let maybePageAsString = searchParams.get("page");

    if (maybePageAsString) {
      let pageAsNumber = Number(maybePageAsString);
      if (!Number.isNaN(pageAsNumber) && pageAsNumber > 0) {
        return pageAsNumber;
      }
    }

    return null;
  }

  syncPageInURL() {
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set("page", this.state.page);
    this.props.history.replace({ search: searchParams.toString() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page) {
      this.syncPageInURL(this.state.page);
    }
  }

  componentDidMount() {
    this.syncPageInURL();
    const { user } = this.props;

    if (this.props.user && Object.keys(this.props.user).length > 0) {
      this.props.getShipments();
      if (deployedRussia() === true) {
        this.props.getSmiles(this.props.user.account_id);
      }
    }
    this.props.getToken();
    this.props.getTasks();
    if (deployedUSA() || (deployedRussia() && this.props.intl.locale === "ru"))
      this.props.getPays();
    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      this.state.page,
      this.state.perPage,
    );
    window.scrollTo(0, 0);
  }

  fm(id) {
    return this.props.intl.formatMessage({ id: id });
  }

  componentDidCatch(e) {
    remoteLog(e, "patients_list");
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (this.props.user.account_id && !next_props.user.account_id) {
      this.props.history.push("/");
    }
    this.setState({ filteredPatients: next_props.patients.patients });
    if (next_props.patients.payment_alert) this.setState({ payment_alert: true });
  }

  open3dPlan(id) {
    const { user } = this.props;
    let langLocale = this.props.lang.locale;
    if (deployedUSA() === true) {
      langLocale =
        PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] ||
        PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(PLAN_LINK_V2(id, this.props.token, langLocale));
    } else {
      window.open(PLAN_LINK_V2_RU(id, this.props.token, langLocale));
    }
  }

  handleFilterChange(str) {
    const string = str.target.value.trimEnd().trimStart().toString().toLowerCase();
    if (str.target.value.length === 0) {
      this.setState({ search: false });
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage,
      );
    } else {
      this.setState({ search: string });
    }
  }

  handleEnterSearch(event) {
    if (event.key === "Enter") {
      const str = event.target.value.trimEnd().trimStart();
      if (str === "") {
        this.setState({ search: false });
      } else {
        this.setState({ search: str });
      }
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage,
      );
    }
  }

  togglePayment() {
    this.setState({
      paymentState: !this.state.paymentState,
    });
  }

  statusColor(patient) {
    if (
      patient.latest_correction.status === "S_TREATMENT_FINISHED" ||
      patient.latest_correction.status === "S_CANCEL_TREATMENT"
    ) {
      return "label label-sm bg-grey tw-rounded";
    } else if (
      patient.latest_correction.status === S_WAITING_FOR_A_PAYMENT ||
      patient.latest_correction.status === S_UNFILLED_CASE ||
      patient.latest_correction.status === S_3D_PLAN_APPROVAL ||
      patient.latest_correction.status === S_RETAKE_IMPRESSIONS
    ) {
      return "label label-sm bg-red tw-rounded";
    } else {
      return "label label-sm bg-green tw-rounded";
    }
  }

  outOf(data) {
    if (data.steps_count_total !== "None" && data.steps_count_completed !== "None") {
      return (
        <span>
          {data.steps_count_completed + " "}
          <FormattedMessage id="pat_table.of" />
          {" " + data.steps_count_total}
        </span>
      );
    } else {
      return null;
    }
  }

  renderPrice(value) {
    if ((value && value === "None") || (!value && value !== 0)) {
      return "";
    }
    return <FormatNumber value={value} />;
  }

  onSelect(str) {
    this.setState({ selectedOptions: str });
    let status = "";
    str.forEach((x) => {
      if ([7, 8, 9].includes(x.value)) {
        status = `${status}7,8,9,`;
      } else if (x.value === 14 || x.value === 15 || x.value === 17) {
        status = `${status}14,15,17,`;
      } else if ([4, 5].includes(x.value)) {
        status = `${status}4,5,`;
      } else {
        status = `${x.value},${status}`;
      }
    });
    this.setState({ status: status.slice(0, status.length - 1) });
    if (status === "") {
      this.clear();
    }
  }

  submit() {
    this.setState({ filteredPatients: [] });
    this.setState({ page: 1 });
    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      this.state.page,
      this.state.perPage,
    );
  }

  clear() {
    this.setState({
      status: false,
      ordering: "correction.timestamp.desc",
      search: false,
      page: 1,
      perPage: PatientsListNew.initialState.perPage,
      selectedOptions: [],
    });
    document.getElementById("patient-search-form-input").value = "";
    this.props.getData();
  }

  onSelectPerPage(newPerPage) {
    this.setState({
      perPage: newPerPage,
    });
    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      this.state.page,
      newPerPage,
    );
  }

  onChangeSort(name) {
    if (name === "patient_id") {
      this.state.ordering === name
        ? this.setState({ ordering: "-patient_id" })
        : this.setState({ ordering: "patient_id" });
    } else if (name === "patient_name") {
      this.state.ordering === name
        ? this.setState({ ordering: "-patient_name" })
        : this.setState({ ordering: "patient_name" });
    } else if (name === "correction.timestamp") {
      this.state.ordering === name
        ? this.setState({ ordering: "correction.timestamp.desc" })
        : this.setState({ ordering: "correction.timestamp" });
    } else if (name === "status") {
      this.state.ordering === name
        ? this.setState({ ordering: "-status" })
        : this.setState({ ordering: "status" });
    }
    setTimeout(() => {
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage,
      );
    });
  }

  handlePageClick(pageNum) {
    window.scrollTo(0, 0);
    this.setState({
      page: pageNum,
    });
    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      pageNum,
      this.state.perPage,
    );
  }

  renderTableBody(patients) {
    return (
      <tbody id="patients-table-body">
        {patients.map((p, i) => {
          let lc = p.latest_correction;
          const { total, paid } = p.total_payments;

          return (
            <tr key={"p" + i} className={"clickable-row " + (i % 2 ? "even" : "odd")} role="row">
              <td style={{ textAlign: "center" }} className="sorting_1">
                {p.patient_id}
                {p.precise_initial_adjust_timestamp !== null ? (
                  <div className="precise_tip">
                    <img style={{ width: "50px", margin: "0 auto" }} src={precise} />
                    <span className="precise_tooltip">
                      <FormattedMessage
                        id="tooltip_precise_first_comment"
                        values={{
                          labelTitle: (
                            <FormatDate value={p.precise_initial_adjust_timestamp} date time />
                          ),
                        }}
                      />
                      <br />
                      <FormattedMessage
                        id="tooltip_precise_last_comment"
                        values={{
                          labelTitle: (
                            <FormatDate value={p.precise_latest_adjust_timestamp} date time />
                          ),
                        }}
                      />
                    </span>
                  </div>
                ) : null}
              </td>
              <td>
                <NavLink to={`/pages/patient/${p.patient_id}`}>
                  <PersonName person={p} />
                </NavLink>
              </td>
              <td>{renderCourse(p.course_id)}</td>
              <td>
                <FormatDate value={p.latest_correction.timestamp} date />
              </td>
              <td>
                {this.state.paymentState ? this.renderPrice(paid) : ""} <br />
              </td>
              <td>{this.state.paymentState ? this.renderPrice(total) : ""}</td>
              <td>
                {this.props.token && p.latest_correction.status === S_3D_PLAN_APPROVAL ? (
                  <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title={this.fm("tooltip.click.approve")}
                    onClick={() => this.open3dPlan(p.patient_id)}
                    className="btn bg-red tw-rounded tw-text-xs tw-text-white"
                    id="test-btn"
                    style={{ width: "200px", fontWeight: "500" }}
                  >
                    <FormattedMessage id="S_3D_PLAN_APPROVAL" />
                    {deployedRussia() ? <i className="icon-action-redo" /> : null}
                  </button>
                ) : (
                  <span
                    className={this.statusColor(p)}
                    style={{
                      padding: "6px 12px",
                      width: "200px",
                      margin: "0 5px 0 0",
                      display: "block",
                      fontWeight: "500",
                    }}
                  >
                    {p.latest_correction.status && (
                      <span>
                        {/* <span className='tw-text-xs tw-font-normal'>{p.number_of_corrections > 1 ? `Correction ${p.number_of_corrections -1}` : null}</span> */}
                        <FormattedMessage id={p.latest_correction.status} />
                      </span>
                    )}
                  </span>
                )}
                {p.latest_correction.status === "S_RETAKE_IMPRESSIONS" &&
                p.latest_correction.notes.length > 0 ? (
                  <span className="tw-mt-2 tw-text-xs">
                    <i className="fa fa-flag tw-mr-2 tw-text-sm tw-text-red-500" />
                    {p.latest_correction.notes[0].title}
                  </span>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  render() {
    const animatedComponents = makeAnimated();
    const { selectedOptions, filteredPatients, page, perPage } = this.state;
    const tasks = this.props.tasks.patients ? this.props.tasks.patients : [];
    const actionRequiredPatients = tasks ? tasks.length : 0;
    const actionRequired = this.state.actionRequired;

    let status_list = statuses_predict;

    return (
      <div className="page-content-wrapper">
        <div className="page-content" id="patients-table">
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <i className="icon-users font-green" />
                <span
                  className="caption-subject font-green bold uppercase"
                  id="patients-table-block-title"
                >
                  <FormattedMessage id="pat_table.block_head" />
                </span>
              </div>
            </div>
            <PatientBonuses />
            <div className="portlet-body">
              <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <Select
                      defaultValue={selectedOptions}
                      value={selectedOptions}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={status_list}
                      getOptionLabel={(status_list) => this.fm(status_list.label)}
                      getOptionValue={(status_list) => status_list.value}
                      onChange={(str) => this.onSelect(str)}
                      placeholder={<FormattedMessage id="table.status" />}
                    />
                  </div>
                  <div className="col-md-3 col-xs-7">
                    <input
                      name="search"
                      maxLength="128"
                      onKeyUp={(e) => this.handleFilterChange(e)}
                      type="search"
                      className="form-control"
                      aria-controls="doctor_pacients_table"
                      id="patient-search-form-input"
                      placeholder={deployedUSA() ? "Search" : "Поиск"}
                      style={{ height: "38px" }}
                      onKeyDown={(e) => this.handleEnterSearch(e)}
                    />
                  </div>
                  <div className="col-md-1 col-xs-1">
                    <button
                      style={{ backgroundColor: "#efefef", marginLeft: "-16px" }}
                      type="submit"
                      onClick={this.submit}
                      className="btn btn-circle"
                      title={deployedUSA() ? "Search" : "Поиск"}
                    >
                      <span className="glyphicon glyphicon-search"></span>
                    </button>
                  </div>
                  <div className="col-md-1 col-xs-2">
                    <button
                      style={{ backgroundColor: "#efefef" }}
                      type="reset"
                      onClick={this.clear}
                      className="btn btn-circle"
                      title={deployedUSA() ? "Clear" : "Очистить"}
                    >
                      <span className="glyphicon glyphicon-refresh"></span>
                    </button>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "10px" }}>
                  <div className="col-lg-2 col-md-4 col-sm-8 col-xs-12">
                    <button
                      onClick={this.togglePayment}
                      className={
                        this.state.payment_alert ? "btn red tw-rounded" : "btn green tw-rounded"
                      }
                      id="test-btn"
                      // ref="toggle-button"
                    >
                      <FormattedMessage
                        id={
                          this.state.paymentState ? "general.payment.close" : "general.payment.show"
                        }
                      />
                    </button>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-8 col-xs-12">
                    <button
                      onClick={() => this.setState({ actionRequired: !actionRequired })}
                      className={!actionRequired ? "btn tw-rounded " : "btn green tw-rounded"}
                      id="action-required-btn"
                    >
                      {!actionRequired ? (
                        <span>
                          <FormattedMessage id="general.action.required" />
                          <span className="badge badge-danger" id="tasks-count">
                            {actionRequiredPatients}
                          </span>
                        </span>
                      ) : (
                        <FormattedMessage id="general.all.patients" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="table-scrollable">
                  {!this.props.spinner ? (
                    <table
                      className="table table-bordered table-hover dataTable no-footer"
                      id="doctor_pacients_table"
                      role="grid"
                    >
                      <thead id="patients-table-head">
                        <tr role="row">
                          <th
                            className={
                              !actionRequired
                                ? this.state.ordering === "patient_id"
                                  ? "sorting_asc"
                                  : "sorting_desc"
                                : ""
                            }
                            style={{ width: 14 }}
                            onClick={() => this.onChangeSort("patient_id")}
                          >
                            {" "}
                            #
                          </th>
                          <th
                            className={
                              !actionRequired
                                ? this.state.ordering === "patient_name"
                                  ? "sorting_asc"
                                  : "sorting_desc"
                                : ""
                            }
                            style={{ width: 111, fontSize: "12px" }}
                            onClick={(e) => this.onChangeSort("patient_name")}
                          >
                            <span style={{ marginRight: "12px" }}>
                              <FormattedMessage id="pat_table.name" />
                            </span>
                          </th>
                          <th style={{ width: 120, fontSize: "12px" }}>
                            <FormattedMessage id="pat_table.casetype" />
                          </th>
                          <th
                            style={{ width: 120, fontSize: "12px" }}
                            onClick={() => this.onChangeSort("correction.timestamp")}
                            className={
                              !actionRequired
                                ? this.state.ordering === "correction.timestamp"
                                  ? "sorting_asc"
                                  : "sorting_desc"
                                : ""
                            }
                          >
                            <span style={{ marginRight: "12px", fontSize: "12px" }}>
                              <FormattedMessage id="pat_table.data.submitted" />
                            </span>
                          </th>
                          <th style={{ width: 60, fontSize: "12px" }}>
                            <FormattedMessage id="pat_table.received" />
                          </th>
                          <th style={{ width: 60, fontSize: "12px" }}>
                            <FormattedMessage id="pat_table.tot_payment" />
                          </th>
                          <th
                            onClick={() => this.onChangeSort("status")}
                            className={
                              !actionRequired
                                ? this.state.ordering === "status"
                                  ? "sorting_asc"
                                  : "sorting_desc"
                                : ""
                            }
                            style={{ width: 141, fontSize: "12px" }}
                          >
                            <FormattedMessage id="pat_table.status" />
                          </th>
                        </tr>
                      </thead>
                      {this.renderTableBody(actionRequired ? tasks : filteredPatients)}
                    </table>
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <Pagination
                      totalItemsCount={this.props.patients.total}
                      onChange={this.handlePageClick}
                      activePage={page}
                      itemsCountPerPage={perPage}
                      pageRangeDisplayed={3}
                    />
                  </div>
                  <PageSizes perPage={perPage} onSelectPerPage={this.onSelectPerPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientsListNew));

function PageSizes({ perPage, onSelectPerPage }) {
  const SIZES = [
    { value: 50, intlId: "fifty" },
    { value: 100, intlId: "hundred" },
    { value: 200, intlId: "two.hundred" },
  ];

  return (
    <div className="col-md-offset-10 col-xs-offset-1 col-sm-offset-1">
      <ul className="pagination" id="pages">
        {SIZES.map((size) => (
          <li key={size.value}>
            <button
              onClick={() => onSelectPerPage(size.value)}
              disabled={perPage === size.value}
              className="btn btn-pagination"
              style={perPage === size.value ? { backgroundColor: "#eaecf3" } : {}}
            >
              <FormattedMessage id={size.intlId} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
