import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { endTreatment } from "../../../actions/end_treatment";
import remoteLog from "../../../common/logging";
import { postBonusPatient } from "../../../actions/doctor_bonuses";
import { deployedRussia, deployedUSA } from "../../../common/utils";
import {
  rushCorrection,
  rushCorrectionOn,
  addExtraCorrection,
} from "../../../actions/post_patient";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    rush_correction: state.rushCorrection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    statusEnd: (patient_id) => dispatch(endTreatment(patient_id)),
    postBonusPatient: (doctor_id, patient_id) => dispatch(postBonusPatient(doctor_id, patient_id)),
    rushCorrection: (data) => dispatch(rushCorrectionOn(data)),
    rushProduction: (patient_id) => dispatch(rushCorrection(patient_id)),
    addExtraCorrection: (patient_id) => dispatch(addExtraCorrection(patient_id)),
  };
};

class PatientCorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canRushProduction:
        this.props.patient.course.correction[this.props.patient.course.correction.length - 1]
          .can_rush_production_order,
      disabled: false,
    };
    this.getCorrectionBtn = this.getCorrectionBtn.bind(this);
    this.statusRetainersBtn = this.statusRetainersBtn.bind(this);
    this.statusEndBtn = this.statusEndBtn.bind(this);
    this.bonusesBtn = this.bonusesBtn.bind(this);
    this.renderCorrectionBtnTooltip = this.renderCorrectionBtnTooltip.bind(this);
    this.fm = this.fm.bind(this);
    this.rushProductionOrder = this.rushProductionOrder.bind(this);
    this.getExtraCorrectionBtn = this.getExtraCorrectionBtn.bind(this);
    this.renderExtraCorrectionBtnTooltip = this.renderExtraCorrectionBtnTooltip.bind(this);
    this.fetchExtraCorrection = this.fetchExtraCorrection.bind(this);
  }

  fm(id) {
    return this.props.intl.formatMessage({ id: id });
  }

  rushProductionOrder() {
    this.props.rushCorrection(true);
    const patient_id = this.props.patient ? this.props.patient.patient_id : null;
    this.props.rushProduction(patient_id);
  }

  componentDidCatch(e) {
    remoteLog(e, "patient_correction");
  }

  componentDidUpdate(nextProps) {
    if (this.props.rush_correction !== nextProps.rush_correction) {
      this.setState({ canRushProduction: this.props.rush_correction });
    }
  }

  renderCorrectionBtnTooltip(course) {
    if (course === "None") {
      return <FormattedMessage id="tooltip.correction.btn.no.course" />;
    } else if (
      course !== "None" &&
      (course === "C_FULL" || course === "C_UNLIMITED" || course === "C_ONE_JAW")
    ) {
      return (
        <div>
          <h6>
            <FormattedMessage id="tooltip.correction.btn.title" />
          </h6>
          <ul style={{ marginRight: "30px" }}>
            <FormattedMessage id="tooltip.correction.btn.payment.status" />
            <br />
            <FormattedMessage id="tooltip.correction.btn.warranty.date" />
            <br />
          </ul>
          <FormattedMessage id="tooltip.correction.btn.error" />
          <br />
          <NavLink to="/pages/support">
            <FormattedMessage id="tooltip.support" />
          </NavLink>
        </div>
      );
    } else {
      return (
        <div>
          <FormattedMessage id="tooltip.corrcetion.btn.support" />
        </div>
      );
    }
  }

  renderExtraCorrectionBtnTooltip(course, can_add_extra_correction) {
    if (course === "None") {
      return <FormattedMessage id="tooltip.correction.btn.no.course" />;
    } else if (course !== "None" && can_add_extra_correction === true) {
      return (
        <div>
          <h6>
            <FormattedMessage id="tooltip.extra.correction.btn.title" />
          </h6>
          <FormattedMessage id="tooltip.correction.btn.error" />
          <br />
          <NavLink to="/pages/support">
            <FormattedMessage id="tooltip.support" />
          </NavLink>
        </div>
      );
    } else {
      return (
        <div>
          <FormattedMessage id="tooltip.no.extra.correction.btn.title" />
        </div>
      );
    }
  }

  getCorrectionBtn(patient_id) {
    const course = this.props.patient.course.course;
    const can_order_correction =
      this.props.patient.course.correction.slice(-1)[0].order_options.can_order_correction;

    if (deployedRussia() === true && can_order_correction) {
      return (
        <div className="form-group">
          <NavLink to={"/pages/patient/" + patient_id + "/correct"} className={"btn green"}>
            <FormattedMessage id="pat.status.button.correction" />
          </NavLink>
        </div>
      );
    } else if (deployedUSA() === true) {
      return (
        <div className="form-group prescription_tooltip_show">
          <NavLink
            to={"/pages/patient/" + patient_id + "/correct"}
            className={!can_order_correction ? "btn green disabled" : "btn green"}
          >
            <FormattedMessage id="pat.status.button.correction" />
          </NavLink>
          {!can_order_correction && deployedUSA() ? (
            <span className="d-inline-block prescription_tooltip">
              {this.renderCorrectionBtnTooltip(course)}
            </span>
          ) : null}
        </div>
      );
    }
  }

  getExtraCorrectionBtn(patient_id) {
    const course = this.props.patient.course.course;
    const can_add_extra_correction =
      this.props.patient.course.correction.slice(-1)[0].order_options.can_add_extra_correction;

    return (
      <div className="form-group prescription_tooltip_show">
        <button
          disabled={!can_add_extra_correction || this.state.disabled}
          onClick={() => this.fetchExtraCorrection()}
          className={
            !can_add_extra_correction || this.state.disabled ? "btn green disabled" : "btn green"
          }
        >
          <FormattedMessage id="pat.status.button.extra.correction" />
        </button>
        <span className="d-inline-block prescription_tooltip">
          {this.renderExtraCorrectionBtnTooltip(course, can_add_extra_correction)}
        </span>
      </div>
    );
  }

  fetchExtraCorrection() {
    this.setState({ disabled: true });
    const patient_id = this.props.patient ? this.props.patient.patient_id : null;
    this.props.addExtraCorrection(patient_id);
  }

  statusEndBtn() {
    if (this.props.patient.course.correction.slice(-1)[0].order_options.can_end_treatment) {
      return (
        <div className="form-group">
          <button
            className="btn gray"
            id="add-comment-btnnn"
            onClick={(e) => {
              this.props.statusEnd(this.props.patient.patient_id);
            }}
          >
            <FormattedMessage id="pat.status.button.end" />
          </button>
        </div>
      );
    }
  }

  statusRetainersBtn(patient_id) {
    const course = this.props.patient.course.course;
    const can_order_retainers =
      this.props.patient.course.correction.slice(-1)[0].order_options.can_order_retainers;
    if (deployedRussia() === true && can_order_retainers) {
      return (
        <div className="form-group">
          <NavLink to={"/pages/patient/" + patient_id + "/retainers"} className="btn green">
            <FormattedMessage id="pat.status.button.retainers" />
          </NavLink>
        </div>
      );
    } else if (deployedUSA() === true) {
      return (
        <div className="form-group prescription_tooltip_show">
          <NavLink
            to={"/pages/patient/" + patient_id + "/retainers"}
            className={!can_order_retainers ? "btn green disabled" : "btn green"}
          >
            <FormattedMessage id="pat.status.button.retainers" />
          </NavLink>
          {course === "C_RETAINERS" ? (
            <span className="d-inline-block prescription_tooltip">
              <FormattedMessage id="tooltip.retainers" />
              <NavLink to="/pages/support">
                <FormattedMessage id="tooltip.support" />
              </NavLink>
            </span>
          ) : null}
        </div>
      );
    }
  }

  bonusesBtn(doctor_id, patient_id) {
    const { patient } = this.props;
    if (patient && Object.keys(patient).length > 0 && patient.bonus_set_free === true) {
      return (
        <div className="form-group">
          <button
            onClick={() => this.props.postBonusPatient(doctor_id, patient_id)}
            className="btn green"
          >
            <FormattedMessage id="bonus.patient.btn" />
          </button>
        </div>
      );
    }
  }

  render() {
    const patient_id = this.props.patient.patient_id;
    const doctor_id = this.props.user.account_id;
    const canRushProduction = this.state.canRushProduction;
    return (
      <div className="portlet light bordered">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-speech font-green" />
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage id="pat.block.right.additional" />
            </span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <button
                  disabled={!canRushProduction}
                  type="button"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    canRushProduction
                      ? this.fm("rush_service_comment")
                      : this.fm("additional_service_rush_ordered")
                  }
                  onClick={(e) => this.rushProductionOrder()}
                  style={{
                    color: "#ffffff",
                    marginRight: "5px",
                    border: "none",
                  }}
                  className="btn green"
                >
                  <FormattedMessage id="additional_service_rush" />
                </button>
              </div>
              {this.statusRetainersBtn(patient_id)}
              {this.statusEndBtn()}
            </div>
            <div className="col-md-3 col-md-offset-13">
              {this.getCorrectionBtn(patient_id)}
              {this.getExtraCorrectionBtn(patient_id)}
              {this.bonusesBtn(doctor_id, patient_id)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatientCorrection));
