import React from "react";
import { buildLocale } from "../../../common/locale";


export default function FormatDate(props) {
  return <span>{formatDate(props)}</span>;
}

export function formatDate({ value, date = true, time }) {
  if (!value) return "";
  if (date && time) {
    return new Date(value.replace(/ /g, "T")).toLocaleString(buildLocale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: 'UTC'
    });
  }
  if (date) {
    return new Date(value).toLocaleDateString(buildLocale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: 'UTC'
    });
  }
  if (time) {
    return new Date(value).toLocaleTimeString(buildLocale);
  }
  return value;
}
