import "./css";
import "./css/tailwind.css";

import * as Sentry from "@sentry/react";
import React, { StrictMode, useEffect, useLayoutEffect } from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { Provider } from "react-intl-redux";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";

import { initializeUser } from "./actions/login";
import { store } from "./app-store";
import { deployedRussia, deployedUSA } from "./common/utils";
import PlanApproveBody from "./components/3d_plan_approve/3d_plan_approve_page_body";
import PlanCancelBody from "./components/3d_plan_correction/3d_plan_correction_page_body";
import PlanRetainersBody from "./components/3d_plan_retainers/3d_plan_retainers_page_body";
import PlanReworkBody from "./components/3d_plan_rework/3d_plan_rework_page_body";
import ThreeShapeAuthPage from "./components/3shape/3shape_authrization_page";
import ThreeShapeCase from "./components/3shape/3shape_case";
import ThreeShapePidCases from "./components/3shape/3shape_pid_cases";
import AdditionalAlignersBody from "./components/additional_aligners/additional_aligners_page_body";
import AdditionalAlignersTableBody from "./components/additional_aligners/additional_aligners_table_body";
import CatchAll from "./components/catch_all";
import ClinicNewPageBody from "./components/clinic/clinic_new_page_body";
import ClinicPageBody from "./components/clinic/clinic_page_body";
import ClinicsListPageBody from "./components/clinic/clinics_list_page_body";
import Contacts from "./components/common/contacts";
import PaymentDetails from "./components/common/payment_details";
import DoctorChangePasswordPage from "./components/doctor/doctor_change_password/doctor_change_password_page_body";
import DoctorPageBody from "./components/doctor/doctor_page_body";
import DoctorPaymentDataPageBody from "./components/doctor/doctor_payment_data_page_body";
import DoctorUpdatePageBody from "./components/doctor/doctor_update_page_body";
import MeetingsPageBody from "./components/doctor/meetings_page_body";
import ShipmentsPageBody from "./components/doctor/shipments_page";
import SupportPage from "./components/doctor/support_page";
import ForgotPassword from "./components/forgot_password";
import HomePageBody from "./components/homepage/homepage_body";
import IntegrationsPageBody from "./components/integrations/integrations_page_body";
import MeditAuthPage from "./components/integrations/medit/authorization";
import MeditCases from "./components/integrations/medit/medit_cases";
import Login from "./components/login";
import ManualsPageBody from "./components/manuals/manuals_page_body";
import Notification from "./components/notification";
import { notificationCustomStyle } from "./components/notifications/notification_styles";
import PatientDeliveryPage from "./components/patient/patient_delivery_page";
import PatientNewPageBody from "./components/patient/patient_new_page_body";
import PatientPage from "./components/patient/patient_page";
import PaymentInvoice from "./components/patient/patient_show/payment_invoice/payment_invoice_body";
import PaymentInvoiceErrorBody from "./components/patient/patient_show/payment_invoice/payment_invoice_error_body";
import PaymentInvoiceSuccessBody from "./components/patient/patient_show/payment_invoice/payment_invoice_success_body";
import PaymentInvoiceStatus from "./components/patient/patient_show/payment_invoice/payment_invoice_status";
import PaymentInvoiceBatchStatus from "./components/patient/patient_show/payment_invoice/payment_invoice_batch_status";
import PatientUpdatePageBody from "./components/patient/patient_update_page_body";
import PatientsListPageBody from "./components/patient/patients_list_page_body";
import PatientsPayPageBody from "./components/patient/patients_pay_page_body";
import PatientsTaskPageBody from "./components/patient/patients_task_page_body";
import TourBtn from "./components/patient/tour_btn";
import PatientArea from "./components/patient_area";
import PatientPaymentError from "./components/patient_area/payment_error";
import PatientPaymentSuccess from "./components/patient_area/payment_success";
import PatientPaymentStatus from "./components/patient_area/payment_status";
import PublicOnly from "./components/public_only";
import PasswordRecovery from "./components/recovery";
import SignOffer from "./components/sign_offer/sign_offer";
import SmilesPage from "./components/smiles";
import Welcome from "./components/welcome";
import { loadDevTools } from "./dev-tools/load";
import { requireOfferSign } from "./require-offer-sign";
import { useAppDispatch } from "./store";
import ScrollToTop from "./components/common/scrollToTop";

const EventRoutes = deployedRussia() ?
  (await import("./components/doctor/events/event_routes")).default :
  () => null;

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(initializeUser());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Route
        path="/pages"
        render={({ location }) => {
          if (!store.getState().user.account_id) {
            return <Welcome to={location.pathname + location.search ? location.search : ""} />;
          } else {
            return null;
          }
        }}
      />
      <Notification style={notificationCustomStyle} />
      <Switch>
        <Route path="/pages/offer" component={SignOffer} />
        {!isMobile && (deployedUSA() || (deployedRussia() && window.location.hostname.endsWith(".ru"))) ? (
          <Route path="/pages/home" component={requireOfferSign(HomePageBody)} />
        ) : null}
        <Route path="/pages/patients" component={requireOfferSign(PatientsListPageBody)} />
        <Route path="/pages/patient/:patient_id/payment/success/:secure" component={requireOfferSign(PaymentInvoiceSuccessBody)} />
        <Route path="/pages/patient/:patient_id/payment/success" component={requireOfferSign(PaymentInvoiceSuccessBody)} />
        <Route path="/pages/patient/:patient_id/payment/status/:invoice_id" component={requireOfferSign(PaymentInvoiceStatus)} />
        <Route path="/pages/patient/payment/batch/status/:batch_id" component={requireOfferSign(PaymentInvoiceBatchStatus)} />
        <Route path="/pages/patient/payment/batch/success/:secure" component={requireOfferSign(PaymentInvoiceSuccessBody)} />
        <Route path="/pages/patient/payment/batch/error/:secure" component={requireOfferSign(PaymentInvoiceErrorBody)} />
        <Route path="/pages/patient/:patient_id/payment/error" component={requireOfferSign(PaymentInvoiceErrorBody)} />
        <Route path="/pages/patient/:patient_id/payment" component={requireOfferSign(PaymentInvoice)} />
        <Route path="/pages/patient/:patient_id/deliveries" component={requireOfferSign(PatientDeliveryPage)} />
        <Route path="/pages/patient/:patient_id/rework/:case_id" component={requireOfferSign(PlanReworkBody)} />
        <Route path="/pages/patient/:patient_id/correct" component={requireOfferSign(PlanCancelBody)} />
        <Route path="/pages/patient/:patient_id/retainers" component={requireOfferSign(PlanRetainersBody)} />
        <Route path="/pages/patient/:patient_id/additional/aligners" component={requireOfferSign(AdditionalAlignersBody)} />
        <Route path="/pages/patient/:patient_id/aligners/additional" component={requireOfferSign(AdditionalAlignersTableBody)} />
        <Route path="/pages/patient/:patient_id/approve/:case_id" component={requireOfferSign(PlanApproveBody)} />
        <Route path="/pages/my_account" component={requireOfferSign(DoctorPageBody)} />
        <Route path="/pages/edit_my_account" component={requireOfferSign(DoctorUpdatePageBody)} />
        <Route path="/pages/doctor/payment/account" component={requireOfferSign(DoctorPaymentDataPageBody)} />
        <Route path="/pages/account/password/update" component={requireOfferSign(DoctorChangePasswordPage)} />
        <Route path="/pages/patient/:patient_id" component={requireOfferSign(PatientPage)} />
        <Route path="/pages/edit_patient/:patient_id" component={requireOfferSign(PatientUpdatePageBody)} />
        <Route path="/pages/shipments" component={requireOfferSign(ShipmentsPageBody)} />
        <Route path="/pages/doctor/clinics/:clinic_id" component={requireOfferSign(ClinicPageBody)} />
        <Route path="/pages/doctor/clinics" component={requireOfferSign(ClinicsListPageBody)} />
        <Route path="/pages/doctor/clinic/create" component={requireOfferSign(ClinicNewPageBody)} />
        <Route path="/pages/tasks" component={requireOfferSign(PatientsTaskPageBody)} />
        <Route path="/pages/payments" component={requireOfferSign(PatientsPayPageBody)} />
        <Route path="/pages/manuals" component={requireOfferSign(ManualsPageBody)} />
        <Route path="/pages/new_patient" component={requireOfferSign(PatientNewPageBody)} />
        <Route
          path="/recover/password"
          render={(routeProps) => (
            <PublicOnly>
              <ForgotPassword {...routeProps} />
            </PublicOnly>
          )}
        />
        {/*
              NOTE: <PublicOnly /> is not used with Login, because Login mutates user and userIsLoading state,
              which causes PublicOnly component to redirect the user to /pages/patients page.
              We want to determine where to redirect the user inside the Login component.
            */}
        <Route path="/login" render={() => <Login />} />
        <Route
          path="/account/recovery/:uuid/:token"
          render={(routeProps) => (
            <PublicOnly>
              <PasswordRecovery {...routeProps} />
            </PublicOnly>
          )}
        />
        {deployedRussia() ? <Route path="/payment/details" component={PaymentDetails} /> : null}
        {deployedRussia() ? <Route path="/contacts" component={Contacts} /> : null}
        <Route path="/patient/area/:patient_id/:token/payment/status/:invoice_id" component={PatientPaymentStatus} />
        <Route path="/patient/area/:patient_id/:token/payment/success/:hash" component={PatientPaymentSuccess} />
        <Route path="/patient/area/:patient_id/:token/payment/error" component={PatientPaymentError} />
        <Route path="/patient/area/:patient_id/:token" component={PatientArea} />
        <Route path="/pages/3shape/:patient_id/cases/:case_id" component={requireOfferSign(ThreeShapeCase)} />
        <Route path="/pages/3shape/authorization" component={requireOfferSign(ThreeShapeAuthPage)} />
        <Route path="/pages/3shape/:patient_id/cases" component={requireOfferSign(ThreeShapePidCases)} />
        {deployedUSA() ? <Route path="/pages/scanners" component={requireOfferSign(IntegrationsPageBody)} /> : null}
        {deployedUSA() ? <Route path="/pages/integrations/medit/authorization" component={requireOfferSign(MeditAuthPage)} /> : null}
        {deployedUSA() ? <Route path="/pages/integrations/medit/cases" component={requireOfferSign(MeditCases)} /> : null}
        <Route path="/pages/smiles" component={requireOfferSign(SmilesPage)} />
        <Route path="/pages/meetings" component={requireOfferSign(MeetingsPageBody)} />
        <Route path="/pages/support" component={requireOfferSign(SupportPage)} />
        {deployedRussia() ? (
          <Route path="/pages/events" render={() => <EventRoutes />} />
        ) : null}
        <Route path="*" component={CatchAll} />
      </Switch>
      <TourBtn />
      {!isMobile && deployedUSA() ? (
        <button id="zendesk" style={{ float: "right" }} className="btn_chat">
        </button>
      ) : null}
      <DocumentTitleReset />
    </Router>
  )
}

/**
 * We are currently adding unique document titles like "3D Smile - Мероприятия" to every page.
 * Some pages still lack them. When you navigate away from a page that has a unique title,
 * the title is persisted on the next page. This component reset's the title for such pages.
 *
 * NOTE: Remove this component after all pages have been given unique titles, it is a temporary solution.
 */
function DocumentTitleReset() {
  const location = useLocation();

  useLayoutEffect(() => {
    document.title = WEBPACK_LABEL_TITLE;
  }, [location.pathname]);

  return null;
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

loadDevTools(() => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </StrictMode>,
    document.getElementById("react_root"),
  );
});
