import React from "react";
import { NavLink } from "react-router-dom";

import { useAppSelector } from "../store";
import ProfileMenu from "./profile_menu";
import chrismas from "../assets/img/3D_Predict_NY.png";

export default function PageHeader() {
  const tour = useAppSelector((state) => state.tour);
  const banners = useAppSelector((state) => state.banners);
  const bannersHome = banners.filter((banner) => banner.align == "center");

  return (
    <div className="page-header navbar mob-navbar">
      <div id="top-navbar" className="page-header-inner container">
        <div className="page-logo">
          <div id="logo">
            <NavLink
              to={bannersHome.length > 0 ? "/pages/home" : "#"}
              style={tour ? { pointerEvents: "none" } : {}}
            >
              <img
                src="/img/3d_predict.svg"
                alt="logo"
                className="logo-default"
                style={{ width: 150, marginTop: 20 }}
              />
            </NavLink>
          </div>
          <div className="menu-toggler sidebar-toggler"></div>
        </div>

        <button
          // NOTE: <PatientMenu /> uses this id to access the element and close the menu.
          id="patient-menu-toggle-btn"
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          style={{ border: "none", backgroundColor: "transparent" }}
          aria-label="Toggle navigation menu"
          aria-controls="patient-menu-sidebar"
        ></button>

        <div className="">
          <img style={{ position: "absolute", zIndex: 1000, width: "50px" }} src={chrismas} />
        </div>

        <div className="page-top">
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
}
